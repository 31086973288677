<template>
  <common-table
      :itemScope="['lsell', 'productInfo']"
      :headers="headers"
      :listData="listData"
      @changePage="changePage"
      :loading="loading"
      :btnAction="true"
      @search="search"
      @remove="remove"
      @save="save"
      ref="ct"
      :rightConfig="rightConfig"
      :leftConfig="leftConfig"
      pageTitle="商品列表"
      :btnActionEvent="btnActionEvent"
      @otherTableOperate="otherTableOperate"
      @otherRightOperate="otherRightOperate"
  >
    <template v-slot:productInfo="{ item }">
      <div class="d-flex align-center mt-2 mb-2">
        <img alt="" :src="item.mlistpic.split(',')[0]" style="width: 64px; height: 64px; border-radius: 8px"/>
        <div
            class="font-weight-bold ml-2 two-line"
            style="color: #333333; height: 38px; line-height: 19px; width: 280px; white-space: normal; font-size: 14px">
          {{ item.description }}
        </div>
      </div>
    </template>
    <template v-slot:lsell="{ item }">
      <w-switches
          inset
          label=""
          color="var(--v-primary-base)"
          @change="updateStatus(item.sqlid)"
          v-model="item.lsell" hide-details dense/>
    </template>
  </common-table>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/hand-greeting/product-list.js';

export default {
  mixins: [commonCurd],
  data() {
    return {
      api,
      // 排序字段，不填默认sqlid
      sortname: 'seq',
      // 定义右侧按钮
      rightConfig: [
        {label: '新建', icon: 'zengjia', event: 'add1'}
      ],
      // 定义左侧搜索框
      leftConfig: [
        {type: 'input', label: '输入商品信息', property: 'searchkey'},
        // {type: 'input', label: '输入商品条形码', property: 'searchkey'},
        {type: 'select', label: '所属类目', property: 'groupid', items: []},
        {
          type: 'select',
          label: '商品状态',
          property: 'lsell',
          items: [{code: true, desc: '是'}, {code: false, desc: '否'}]
        }
      ],
      btnActionEvent: [
        {
          event: 'editItem1',
          icon: 'bianji2-fill',
          color: 'primary',
          tooltip: '编辑'
        },
        {
          event: 'toShare',
          icon: 'tiaozhuan-fill',
          color: 'primary',
          tooltip: '分享'
        }
        , {
          event: 'deleteItem',
          icon: 'shanchu-fill',
          color: 'var(--v-redness-base)',
          tooltip: '删除'
        }
      ],
      // 表格头部字段
      headers: [
        {text: '商品信息', value: 'productInfo', width: '320px'},
        {text: '所属类目', value: 'groupid'},
        {text: '价格', value: 'showprice'},
        {text: '状态', value: 'lsell'},
        {text: '排序号', value: 'seq'},
        {text: '发布时间', value: 'createtime'},
        {text: '操作', value: 'actions', width: 120}
      ]
    }
  },
  methods: {
    otherTableOperate(event, item) {
      if (event === this.btnActionEvent[0].event) {
        this.$router.push({path: '/product-edit', query: {sqlid: item.sqlid}})
      } else if (event === this.btnActionEvent[1].event) {
        console.log('分享', item)
      }
    },
    otherRightOperate(event) {
      if (event === this.rightConfig[0].event) {
        this.$router.push('/product-add')
      }
    }
  },
  mounted() {
    this.axios.post(this.select_data, {keyname: "gift"}).then((res) => {
      if (res?.data) {
        this.leftConfig[1].items = res.data[0].values
      }
    })
  }
}
</script>
